import Head from 'next/head'
import React, { Fragment, useEffect } from 'react'
import Language from '../components/Common/Language'
import BenefitsSection from '../components/Homepage/BenefitsSection'

import ChatSection from '../components/Homepage/ChatSection'
import Companies from '../components/Homepage/Companies'
import DetailsSection from '../components/Homepage/DetailsSection'
import Footer from '../components/Homepage/Footer'
import JoinSection from '../components/Homepage/JoinSection'
import TestimonialSection from '../components/Homepage/TestimonialSection'

import TopSection from '../components/Homepage/TopSection'
import { GlobalDarkWhiteBg } from '../styles/styledUtils'
import styled from 'styled-components'
import Image from 'next/image'

type Props = {}
const LandingPage = ({}: Props) => {
  return (
    <Fragment>
      <Head>
        {/* <!-- Start of HubSpot Embed Code --> */}
        {process.env.NODE_ENV === 'production' && (
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-na1.hs-scripts.com/4460392.js"
          ></script>
        )}
      </Head>
      {/* <GlobalDarkWhiteBg isDark /> */}
      <Container>
        <InnerContainer>
          <div>
            <Image
              id="company-logo"
              width={130}
              height={20}
              src={
                'https://res.cloudinary.com/dicnezd6a/image/upload/v1715435130/logo_white_jxu6sv.png'
              }
              alt="PassionBits"
            />
          </div>
          <h1 style={{ color: 'white', fontSize: '42px', lineHeight: '120%' }}>
            Coming Soon
          </h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <p>Get In Touch</p>
            <Links>
              <Anchor
                rel="noreferrer"
                href="https://mobile.twitter.com/passionbits_io"
                target="_blank"
              >
                <Image
                  src={
                    'https://res.cloudinary.com/dicnezd6a/image/upload/v1715435117/twitter_xysmbw.svg'
                  }
                  width="20px"
                  height="20px"
                  layout="fixed"
                  objectFit="cover"
                  alt="Twitter"
                />
              </Anchor>
              <Anchor
                rel="noreferrer"
                href="https://www.linkedin.com/company/passionbits/"
                target="_blank"
              >
                <Image
                  src={
                    'https://res.cloudinary.com/dicnezd6a/image/upload/v1715435118/linkedin_ztrsfi.svg'
                  }
                  width="20px"
                  height="20px"
                  layout="fixed"
                  objectFit="cover"
                  alt="LinkedIn"
                />
              </Anchor>
              {/*  <Anchor
          rel="noreferrer"
          href="https://www.instagram.com/passionbits/"
          target="_blank"
        >
          <Image
            src={'/images/footer/facebook.svg'}
            width="20px"
            height="20px"
            layout="fixed"
            objectFit="cover"
            alt="Facebook"
          />
        </Anchor> */}
              <Anchor
                rel="noreferrer"
                href="https://www.instagram.com/passionbits/"
                target="_blank"
              >
                <Image
                  src={
                    'https://res.cloudinary.com/dicnezd6a/image/upload/v1715435117/instagram_jy9ejr.svg'
                  }
                  width="20px"
                  height="20px"
                  layout="fixed"
                  objectFit="cover"
                  alt="Instagram"
                />
              </Anchor>
            </Links>
          </div>
        </InnerContainer>
      </Container>
      {/* <!-- End of HubSpot Embed Code -->
      <Language />
      <TopSection />
      <Companies />
      <DetailsSection />
      <ChatSection />
      <BenefitsSection />
      <TestimonialSection />
      <JoinSection />
      <Footer />
    */}
    </Fragment>
  )
}

export default LandingPage

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  background-color: black;
`
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 16px;

  margin-bottom: 30px;
`

const Anchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  width: 40px;
  height: 40px;

  border: 0.25px solid #f8481c;
  box-sizing: border-box;
`
